<template>
  <multiselect
    name="multi-destinations-theme0"
    placeholder=""
    :showLabels="false"
    v-model="value"
    :options="availableOptions"
    :multiple="true"
    :closeText="$t('search-tab.choose-count-select')"
    track-by="id"
    :custom-label="customLabel"
    :closeOnSelect="false"
    @select=onSelect($event)
    @remove=onRemove($event)
    @close=selecting
    @open=deselecting
    ref="multiselect"
    >
    <template slot="selection" slot-scope="{ values }">
      <span class="multiselect__single" v-if="values.length === 1 && values[0].id === 0">
        <i class="fa fa-hotel" aria-hidden="true"></i> {{$t("search-tab.all-hotels")}} {{ destination }} {{$t("search-tab.or-select")}}
      </span>
      <span class="multiselect__single" v-else>
        <i class="fa fa-hotel" aria-hidden="true"></i>
        <span v-if="values.length === 1"> {{ values[0].label }} </span>
        <span v-else>{{ values.length }} {{$t("search-tab.hotels-selected")}}</span>
      </span>
    </template>

    <span class="checkbox-label" :class="isRTLPage ? 'rtl' : 'ltr'" slot="option" slot-scope="scope" @click.self="select(scope.option)">
      <input name="checkbox-label" type="checkbox" v-model="scope.option.checked" @focus.prevent/>
      <span> {{ scope.option.label }} </span>
    </span>
  </multiselect>
</template>

<script>
import TemplateMultipleSelect from './TemplateMultipleSelect';

export default {
  name: 'MultipleSelectTheme0',
  mixins: [TemplateMultipleSelect],
  components: {
    Multiselect: () => import('@/components/atoms/multiSelect/Multiselect'),
  },
};
</script>
<style>
.filter-Wrap1 .multiselect__select{
  top: 7px;
}
.filter-Wrap1 .multiselect__strong{
  margin: 0;
}
.filter-Wrap1 .multiselect__tags, .multiselect__select{
  height: 50px;
  border-radius: 30px;
}
.filter-Wrap1 .multiselect .multiselect__tags{
  max-height: 50px;
  overflow: hidden;
  padding: 15px 18px 0 40px;
}
.filter-Wrap1 .multiselect__option--highlight {
    background: #5897fb;
}
.filter-Wrap1 .multiselect__tag{
  margin-right: 1px;
  margin-bottom: 1px;
  display: inline-flex;
}
.filter-Wrap1 .multi-hotels .multiselect__content li:first-child{
  margin-bottom: 1.5rem;
  border-bottom: 1px solid;
}
.filter-Wrap1 .multi-hotels .multiselect__content li:first-child>span{
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.filter-Wrap1 .multiselect__content-wrapper .close-button{
  position: sticky;
  top: 10px;
  text-align: left;
  z-index: 100;
  height: 0;
}
</style>
<style scoped>
.multiselect__single {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: justify;
}
.multiselect__single > i{
  margin-left: 10px;
}
.checkbox-label {
  display: block;
  margin-right: 20px;
}
.checkbox-label.ltr {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
</style>
